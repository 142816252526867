.cursor-pointer {
  cursor: pointer;
}

.new-product-spec {
  background-color: rgba(31, 122, 136, 0.25) !important;
}

.Toastify__toast-container {
  display: inline-table;
}